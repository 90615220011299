import { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import Layout from './components/Layout';
import Chat from './components/Chat'
import Snackbar from './components/Elements/Snackbar'
import defaultTheme from './theme';
import { StoreProvider } from './store';
import useSetFavicon from './hooks/useSetFavicon';
import { SignInForm } from './components/SignInForm'
import { CenteredLoader } from './components/Elements/CenteredLoader';
import usePilotData from './hooks/usePilotData';

import './App.scss';

function App() {
    const [isAuth,  setIsAuth] = useState<boolean>(true);
    const {
        style,
        pilotData,
        clientName,
        title,
        tenantId,
        policy,
        isLoading,
    } = usePilotData(isAuth, setIsAuth);
    useSetFavicon(style?.bucketUrl);

    if (isLoading) {
        return <CenteredLoader />;
    }

    const assistanceLogoUrl = style?.bucketUrl ? style?.bucketUrl + '/logo-assistance.svg' : null;
    return (
        <ThemeProvider theme={{
            ...defaultTheme,
            ...style?.theme
        }}>
          <StoreProvider>
            <Layout
                clientName={clientName || ''}
                title={title || ''}
                editData={{
                  logo: style?.bucketUrl ?
                      style?.bucketUrl  + '/logo.svg' :
                      null,
                  logoTitle: style?.logoTitle,
                  disableClientName: style?.disableClientName,
                  sidebarImage: style?.bucketUrl ? style?.bucketUrl + '/sidebar.svg' :
                      null,
                  policies: {
                      termsOfService: policy.termsOfService || '',
                      privacyPolicy: policy.privacyPolicy || ''
                  }
                }}
            >
                {
                    !isAuth
                        ? <SignInForm tenantId={tenantId as string} onAuth={setIsAuth}/>
                        : <Chat pilotData={{...pilotData, assistanceLogoUrl}}/>
                }
              <Snackbar
                type="error"
                title="Error file upload"
                message="File upload only supports this format, please try again"
                open={false}
              />
            </Layout>
          </StoreProvider>

        </ThemeProvider>
  );
}

export default App;