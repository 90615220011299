import React, {ReactElement, ReactNode, useRef, useState, useEffect} from 'react';
import { Typography, Box, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Chart } from 'react-chartjs-2';
import { marked } from 'marked';
import { generateHtmlPDF } from '../../utils/pdf/generatePDF';
import { updateDiagramColors, parseStringDiagramToJson} from './utils';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    RadialLinearScale,
    RadarController,
    PolarAreaController,
    PieController,
    DoughnutController,
    BarController,
    LineController,
    Title,
    Tooltip,
    Legend,
    Filler,
    Decimation,
    BubbleController,
    ScatterController,
    SubTitle,
} from 'chart.js';

ChartJS.register(
    RadialLinearScale,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    ArcElement,
    PieController,
    BarController,
    LineController,
    DoughnutController,
    PolarAreaController,
    RadarController,
    BubbleController,
    ScatterController,
    Title,
    Tooltip,
    Legend,
    Filler,
    Decimation,
    SubTitle,
);

interface MessageWithDiagramProps {
    children: ReactNode;
    isGeneratePdf: boolean;
    setGeneratePdf: (body: boolean)=> void
    clientName: string
}

function decodeHtml(html: any) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const MessageWithDiagram: React.FC<MessageWithDiagramProps> = ({children, isGeneratePdf, setGeneratePdf, clientName  }) => {
    const containerRefs = useRef<any>([]);
    const [elements, setElements] = useState<ReactElement[]>([]);
    const message = children as string;
    const parts = message?.split(/FORMATTED_SNIPPET|\/FORMATTED_SNIPPET/g);

    useEffect(() => {
        if (isGeneratePdf) {
            generateHtmlPDF(containerRefs, elements, clientName)
                .finally(() => {
                    setGeneratePdf(false);
                });
        }
    }, [isGeneratePdf, elements]);

    useEffect(() => {
        const textElements: ReactElement[] = [];
        const diagramElements: ReactElement[] =[];

        parts?.forEach((part: string, i: number) => {
            if (i % 2 === 0) {
                const htmlContent: any = marked(part);
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlContent, 'text/html');
                const htmlText = doc.body.innerHTML;

                const html = htmlText
                    .replace('<pre>', '')
                    .replace('</pre>', '')
                    .replace('<code>', '')
                    .replace('</code>', '')
                    .trim();
                textElements.push(<span key={`${i}-${Date.now()}`} dangerouslySetInnerHTML={{ __html: decodeHtml(html) }}></span>);
            }
            else {
                try {
                    let spec = parseStringDiagramToJson(part.trim());
                    if (spec.type) {
                        spec = updateDiagramColors(spec);
                        const newRef = React.createRef();
                        containerRefs.current.push(newRef);

                        diagramElements.push(
                            <Box
                                ref={newRef}
                                style={{ paddingTop: '30px', paddingBottom: '20px', height: "500px" }}
                                key={`${i}-${new Date().getTime()}`}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Chart
                                    type={spec.type}
                                    data={spec.data}
                                    options={spec.options}
                                />
                            </Box>
                        );
                    }
                } catch (e) {
                    console.error('Error parsing diagram', e);
                    return <span key={`${i}-${new Date().getTime()}`}>[Error in the diagram]</span>;
                }
            }
        });

        setElements([...textElements, ...diagramElements]);
    }, [children]);

    return <StyledTypography variant="body1" component="div">{elements}</StyledTypography>
}

const StyledTypography = styled((props: TypographyProps) => <Typography {...props} />)`
    color: #171D1A;
    fontFamily: Poppins;
    fontSize: 16px;
    fontStyle: normal;
    fontWeight: 400;
    lineHeight: 150%;
`;




