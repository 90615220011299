
export const applyStrongElementsStyle = (el: HTMLElement) => {
    const strongElements: NodeListOf<HTMLElement> = el.querySelectorAll('strong');

    strongElements.forEach((strongElement: HTMLElement) => {
        strongElement.style.color = 'black';
    });
}

export const applyListStyles = (element: any) => {
    element.querySelectorAll('ul, ol').forEach((listElement: HTMLOListElement) => {
        if (!listElement.closest('li')) {
            listElement.style.paddingTop = '2px';
            listElement.style.paddingBottom = '8px';
        }

        listElement.style.display = 'block';
        listElement.style.listStylePosition = 'outside';
        listElement.style.marginLeft = '0';
        listElement.style.margin = '0';

        listElement.querySelectorAll(':scope > li').forEach((li: any) => {
            li.style.marginBottom = '0';
            li.style.display = 'block';
            li.style.alignItems = 'start';
            li.style.position = 'relative';

            const existingMarker = li.querySelector(':scope > span');
            if (existingMarker) {
                li.removeChild(existingMarker);
            }

            const marker = document.createElement('span');
            marker.style.marginRight = '3px';
            marker.style.marginTop = '0';
            marker.style.float = 'left';

            if (listElement.tagName === 'OL') {
                marker.textContent = (Array.from(listElement.children).indexOf(li) + (Number(listElement.start) || 1)) + '.';
            } else if (li.parentElement.closest('li')) {
                marker.textContent = '-';

            } else {
                marker.textContent = '•';
            }

            li.insertBefore(marker, li.firstChild);

            li.querySelectorAll('ul, ol').forEach((nestedList: any) => {
                nestedList.style.paddingLeft = '10px';
            });
        });
    });
};

export const applyHrStyles = (element: any) => {
    element.querySelectorAll('hr').forEach((hrElement: HTMLHRElement) => {
        hrElement.style.border = 'none';
        // hrElement.style.height = '1px';
        // hrElement.style.marginTop = '10px';
        hrElement.style.marginBottom = '0px';
        hrElement.style.backgroundColor = '#333';
    })
}

export const applyH1Styles = (element: any) => {
    element.querySelectorAll('h1').forEach((hrElement: HTMLHRElement) => {
        console.log(hrElement);
        hrElement.style.marginBottom = '10px';
        hrElement.style.fontWeight = 'bold';
        hrElement.style.textTransform = 'uppercase';
    })
}

//
// export const applyListStyles = (element: any) => {
//     element.querySelectorAll('ul, ol').forEach((el: HTMLElement) => {
//         el.style.display = 'block';
//         el.style.listStylePosition = 'inside';
//         el.style.marginLeft = '0';
//         el.style.margin = '0';
//     });
//
//     element.querySelectorAll('li').forEach((el: HTMLElement) => {
//         el.style.marginBottom = '0';
//     });
//
//     const applyStylesToListItems = (list: HTMLElement, isOrdered: boolean, counter = 1) => {
//         list.querySelectorAll(':scope > li').forEach((li: any) => {
//             const marker = document.createElement('span');
//             marker.textContent = isOrdered ? `${counter}.` : '•';
//             marker.style.marginRight = '3px';
//             marker.style.marginTop = '0';
//
//             li.style.position = 'relative';
//             li.style.display = 'flex';
//             li.style.alignItems = 'start';
//             li.insertBefore(marker, li.firstChild);
//
//             const nestedList = li.querySelector(':scope > ul, :scope > ol');
//             if (nestedList) {
//                 applyStylesToListItems(nestedList, nestedList.tagName === 'OL', isOrdered ? counter + 1 : 1);
//             }
//
//             if (isOrdered) {
//                 counter++;
//             }
//         });
//     };
//
//     element.querySelectorAll('ol').forEach((ol: HTMLElement) => {
//         applyStylesToListItems(ol, true);
//     });
//
//     element.querySelectorAll('ul').forEach((ul: HTMLElement) => {
//         applyStylesToListItems(ul, false);
//     });
// };
//
