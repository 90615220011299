import { useLocation } from 'react-router-dom';

export const useGetPilotNameFromUrl = () => {
    const location = useLocation();

    if (!location.hash) {
        return {
            pilotName: '',
            query: ''
        };
    }
    const pathParts = location.hash.split('/').filter(Boolean);

    const [pilotName, query] = pathParts[pathParts.length - 1].split('?');
    return { pilotName, query };
};
