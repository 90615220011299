export const getCurrentUSDateTime = (): string => {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const year = currentDate.getFullYear();

    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');

    const meridiem = +hours >= 12 ? 'PM' : 'AM';
    return `${month}-${day}-${year} ${hours}-${minutes}${meridiem}`;
}