import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {getCurrentUSDateTime} from '../../../../../common/helpers/date';
import {applyListStyles, applyHrStyles, applyStrongElementsStyle, applyH1Styles} from './querySelectors';

// In mm
const pdfMargin = [20, 0, 20, 0];

const stylesSpanElement = (el: HTMLElement) => {
    el.style.display = 'inline';
    el.style.float = 'left';
    el.style.fontSize = '4px';
    el.style.wordBreak = 'break-word';
    el.style.width = '200px';
    el.style.fontFamily = 'sans-serif';
    el.style.paddingRight = '15px';
    el.style.paddingLeft = '10px';
    el.style.margin = '0px';
};

const extractPdfName = (clientName: string) => {
    const hash = window.location.hash;
    const hashPart = hash.split('#/')[1] || '';
    const pdfNamePart = hashPart.split('?')[0];
    return clientName + '_' + pdfNamePart + '_' + getCurrentUSDateTime() + '.pdf';
};

function isList(htmlString: string) {
    return htmlString.includes('<ul') || htmlString.includes('<ol');
}

const processList = (listNode: any, pageHeight: number) => {
    const mmToPixels = 3.78;
    const targetHeightInPx = pageHeight * mmToPixels;

    const listItems = Array.from(listNode.querySelectorAll('li'));
    let lastItemIndexPerPage: any = {};
    let currentPage = 0;

    listItems.forEach((li: any, index) => {
        const rect = li.getBoundingClientRect();
        const currentTop = rect.top + window.scrollY; // Учитываем прокрутку страницы

        currentPage = Math.floor(currentTop / targetHeightInPx);
        lastItemIndexPerPage[currentPage] = index;
    });

    Object.values(lastItemIndexPerPage).forEach((index: any) => {
        const li: any = listItems[index];
        li.style.marginTop = '15px';
    });
};


function splitHtmlIntoSpans(htmlElement: any, wordsPerSpan = 50): any {
    const text = htmlElement.props.dangerouslySetInnerHTML.__html;
    if (text === '') {
        return;
    }
    const doc = new DOMParser().parseFromString(text, 'text/html');
    const parentDiv = document.createElement('div');
    let currentSpan = document.createElement('span');
    let wordCount = 0;

    const appendCurrentSpan = () => {
        if (currentSpan.innerHTML.trim()) {
            parentDiv.appendChild(currentSpan);
            currentSpan = document.createElement('span');
        }
    };

    const processTextNode = (node: any) => {
        node.textContent.split(/\s+/).forEach((word: any) => {
            currentSpan.innerHTML += word + ' ';
            if (++wordCount >= wordsPerSpan) {
                appendCurrentSpan();
                parentDiv.appendChild(document.createTextNode(' '));
                wordCount = 0;
            }
        });
    };

    const processElementNode = (node: any) => {
        if (node.nodeName === 'P' && node.childNodes.length === 1 && node.childNodes[0].nodeType === Node.TEXT_NODE) {
            appendCurrentSpan();
            // parentDiv.appendChild(document.createElement('br'));
            processTextNode(node.childNodes[0]);
        } else {
            appendCurrentSpan();
            currentSpan.appendChild(node.cloneNode(true));
        }
    };

    Array.from(doc.body.childNodes).forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            processTextNode(node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            processElementNode(node);
        }
    });

    appendCurrentSpan();
    applyListStyles(parentDiv);
    applyHrStyles(parentDiv);
    applyStrongElementsStyle(parentDiv);
    applyH1Styles(parentDiv);

    return parentDiv;
}

export const generateHtmlPDF = async (containerRefs: any, elements: any, clientName: string) => {
    const pdf = new jsPDF({
        format: 'letter',
        unit: 'mm'
    });
    const pdfName = extractPdfName(clientName);

    let currentY = 0;
    const pageHeight = pdf.internal.pageSize.getHeight() - (pdfMargin[0] + pdfMargin[2]);
    const pageWidth = pdf.internal.pageSize.getWidth();

    let pageNumber = 1;

    // const headerText = "Combined Recommendations for Social Media Post";
    // const headerWidth = pdf.getTextWidth(headerText);
    pdf.setFontSize(16);
    // const centerX = (pageWidth - headerWidth) / 2;
    // pdf.text(headerText, centerX, currentY);
    currentY += 10;

    for (const elem of elements) {
        if (elem?.type === 'span') {
            const parentDiv = splitHtmlIntoSpans(elem, 1);
            if (!parentDiv) {
                continue;
            }
            let tempDiv = document.createElement('span');
            stylesSpanElement(tempDiv);

            for (let span of parentDiv.children) {
                let clonedSpan = span.cloneNode(true);
                tempDiv.appendChild(clonedSpan);

                document.body.appendChild(tempDiv);

                const contentHeight = tempDiv.offsetHeight;
                if ((currentY + contentHeight) + 2 > pageHeight * pageNumber) {
                    tempDiv.removeChild(clonedSpan);

                    await pdf.html(tempDiv, { x: 10, y: currentY, margin: pdfMargin });
                    if (tempDiv.parentNode) {
                        document.body.removeChild(tempDiv);
                    }

                    tempDiv = document.createElement('span');
                    stylesSpanElement(tempDiv);

                    tempDiv.appendChild(clonedSpan);
                    currentY += pageHeight - (currentY % pageHeight);
                    pageNumber++;
                }

                if (tempDiv.parentNode) {
                    document.body.removeChild(tempDiv);
                }
            }

            if (tempDiv.hasChildNodes()) {
                document.body.appendChild(tempDiv);
                await pdf.html(tempDiv, { x: 10, y: currentY, margin: pdfMargin });

                currentY += tempDiv.offsetHeight;

                if (tempDiv.parentNode) {
                    document.body.removeChild(tempDiv);
                }
            }
        }
        // else if (elem.props?.children?.type === Vega) {
        //     const canvas = await html2canvas(containerRefs.current[index].current);
        //     const imageData = canvas.toDataURL('image/png');
        //     const img = document.createElement('img');
        //     img.src = imageData;
        //     img.width = 170;
        //     // img.height = img.naturalHeight > 150 ? img.naturalHeight : 150;
        //     img.height = 125;
        //
        //
        //     const tempDiv = document.createElement('div');
        //     tempDiv.style.width = '200px';
        //     tempDiv.style.display = 'flex';
        //     tempDiv.style.justifyContent = 'center';
        //     tempDiv.style.alignItems = 'center';
        //     tempDiv.appendChild(img);
        //
        //     document.body.appendChild(tempDiv);
        //
        //     if ((currentY + img.height) > pageHeight * pageNumber) {
        //         currentY += pageHeight - (currentY % pageHeight);
        //         pageNumber++;
        //     }
        //
        //     await pdf.html(tempDiv, { x: 10, y: currentY });
        //     currentY += img.height;
        //     document.body.removeChild(tempDiv);
        //     index++;
        // }
    }


    if (containerRefs && containerRefs.current.length > 0) {
        for(const ref of containerRefs.current) {
            const canvas = await html2canvas(ref.current);
            const imageData = canvas.toDataURL('image/png');
            const img = document.createElement('img');

            img.src = imageData;
            img.width = 180;
            img.height = pageHeight / 2 - 10;

            const tempDiv = document.createElement('div');
            tempDiv.style.width = pageWidth + 'px';
            tempDiv.style.maxHeight = (pageHeight / 2) + 'px';
            tempDiv.style.display = 'flex';
            tempDiv.style.justifyContent = 'center';
            tempDiv.style.alignItems = 'center';
            tempDiv.style.paddingLeft = '0';
            tempDiv.style.paddingRight = '0';
            // tempDiv.style.scale = '0.95';
            // tempDiv.style.border = '1px solid red';



            tempDiv.appendChild(img);

            document.body.appendChild(tempDiv);

            if ((currentY + img.height) > pageHeight * pageNumber) {
                currentY += pageHeight - (currentY % pageHeight);
                pageNumber++;
            }

            await pdf.html(tempDiv, { x: 0, y: currentY });
            currentY += img.height;
            document.body.removeChild(tempDiv);
        }
    }

    pdf.save(pdfName);
};