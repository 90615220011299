import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Typography,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import variables from "../../../styles/vars.module.scss";

interface Props {
  text: string;
  progress: number;
}

export default function ChatItemLoading(props: Props) {
  return (
    <StyledBox>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <Typography className="loading-message">
            {props.text}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="loading-progress-value">
            {`${Math.round(props.progress)}%`}
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1.5} className="loading-progress-bar">
          <StyledLinearProgress variant="determinate" value={props.progress} />
        </Grid>
      </Grid>
    </StyledBox>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: 331,

  '& .loading-message, & .loading-progress-value': {
    color: variables.textPlaceholderColor,
    fontSize: 16,
    textAlign: 'left',
    fontStyle: 'italic',
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: '-0.16px',
  },
  '& .loading-progress-value': {
    textAlign: 'right',
  }
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 15,
  height: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E4E7EB',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: theme.palette.custom?.mainGradientPrimary || variables.mainGradientPrimary,
  },
}));
