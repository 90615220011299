import { useEffect } from 'react';
import { useStore } from '../../../store';

type ChatQuestionsProps = {
    addMessage: (body: any) => void;
}

export const ChatQuestions = ({ addMessage }: ChatQuestionsProps) => {
    const {state} = useStore();

    useEffect(() => {
        const currentQuestion = state.chat.currentQuestion;
        if (!currentQuestion?.isFinishAllQuestions) {
            const qList= state.chat.questionnaire.questions;
            if (qList.length) {
                addMessage({ role: 'Assistance', text: qList[currentQuestion?.index || 0].text, id: Date.now()});
            }
        }
    }, [state.chat.currentQuestion]);

    return <></>;
}
