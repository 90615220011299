import { createTheme } from '@mui/material/styles';
import variables from './styles/vars.module.scss';
import '@mui/material/styles/createPalette';

declare module '@mui/material/styles/createPalette'
{
  interface Palette {
    size?: Record<string, any>;
    custom?: Record<string, any>;
  }
  interface PaletteOptions {
    size?: Record<string, any>;
    custom?: Record<string, any>;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: variables.mainColorPrimary,
    },
    secondary: {
      main: variables.mainColorSecondary,
    },
    error: {
      main: variables.redColor,
    },
    custom: {
      mainGradientPrimary:  variables.mainColorPrimary,
      secondaryColor: variables.mainColorPrimary,
      policyFieldBackground: variables.backgroundDarkPrimary,
      policyFieldColor: '#718096',
      mainFieldColor: variables.backgroundGradientPrimary,
      backgroundSidebar: variables.backgroundGradientSecondary,
      colorChatIcon: '#FFFFFF',
      borderChatItemColor: '#FAFFFA',
      chatScrollColor: variables.mainColorPrimary,

      logoWeight: 40,
      logoHeight: 40,
      logoPositionDirection: 'row'
    }
  },
  typography: {
    fontFamily: 'Mulish',
    h1: {
      fontSize: '1.5rem',
      fontWeight: 700,
      '@media (max-width:1199px)': {
        fontSize: '1.4rem',
      },
      '@media (max-width:599px)': {
        fontSize: '1.3rem',
      },
    },
    h2: {
      fontSize: '1.4em',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.3rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.2rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.1rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '0.9rem',
      fontWeight: 700,
    },
  },
});

export default theme;
