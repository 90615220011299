import { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from "@mui/material";
import { CustomModal } from '../../Modal';

const preventDefault = (event: React.SyntheticEvent) => {
  if (event.target === event.currentTarget) {
    event.preventDefault();
  }
};

type Props = {
  policies: {
    termsOfService: string,
    privacyPolicy: string
  }
}

export default function Nav(props: Props) {
  const [titleModal, setTitleModal] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (title: string, content: string) => {
    setTitleModal(title);
    setIsModalOpen(true);
    setModalContent(content);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  const modalLinks = useMemo(() => {
    return [
      { title: 'Terms and Conditions', content: props.policies.termsOfService },
      { title: 'Privacy Policy', content: props.policies.privacyPolicy },
    ];
  }, [props.policies]);

  return (
      <StyledBox
          sx={{
            typography: 'body1',
          }}
          onClick={preventDefault}
      >
        {modalLinks.map((link, index) => (
            <Link
                key={index}
                component="button"
                onClick={() => openModal(link.title, link.content)}
            >
              {link.title}
            </Link>
        ))}
        <CustomModal isOpen={isModalOpen} onClose={closeModal} title={titleModal}>
          <Typography variant="body1">{modalContent}</Typography>
        </CustomModal>
      </StyledBox>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  // backgroundColor: variables.backgroundDarkPrimary,
  backgroundColor: theme.palette.custom?.policyFieldBackground,

  '& .MuiLink-root': {
    display: 'inline-block',
    fontSize: 12,
    // color: '#718096',
    color: theme.palette.custom?.policyFieldColor,
    margin: theme.spacing(1.5, 2),
    textDecoration: 'none',
    borderColor: 'rgba(226, 232, 240, 0.20)',
    '&:hover': {
      color: '#FFF',
    },
  },
}));
