import { useEffect } from 'react';

const useSetFavicon = (url: string | undefined): void => {
    useEffect(() => {
        if (!url) {
            return;
        }

        const image = new Image();
        const faviconUrl = `${url}/favicon.svg`;

        image.onload = () => {
            document.querySelectorAll("link[rel='icon'], link[rel='shortcut icon'], link[rel='apple-touch-icon']").forEach((linkElement) => {
                const link = linkElement as HTMLLinkElement;
                link.href = faviconUrl;
            });
        };

        image.onerror = () => {
            console.error('Favicon image could not be loaded:', faviconUrl);
        };

        image.src = faviconUrl;
    }, [url]);
};

export default useSetFavicon;



