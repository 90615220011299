import {useEffect, useState, useMemo} from 'react';
import useWelcomeMessage from './useWelcomeMessage';

const defaultPolicy = {
    termsOfService: '',
    privacyPolicy: '',
}

const usePilotData = (isAuth: boolean, setIsAuth: (auth: boolean) => void) => {
    const [ tenantId,  setTenantId] = useState<string>();
    const [style, setStyle] = useState<any>(null);
    const [clientName, setClientName] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [policy, setPolicy] = useState(defaultPolicy);
    const pilotData = useWelcomeMessage();

    useEffect(() => {
        const { welcomeObject, isError, error } = pilotData;

        if (welcomeObject) {
            setTitle(welcomeObject.title || 'JumpStart');
            setClientName(welcomeObject.clientName);
            setStyle(welcomeObject?.style);
            setPolicy({
                termsOfService: welcomeObject.termsOfService,
                privacyPolicy: welcomeObject.privacyPolicy,
            });
            document.title = welcomeObject.title || 'JumpStart';
            if (!isAuth) {
                setIsAuth(true);
            }
        } else if (isError) {
            const dataError: any = error.response?.data;
            const errorMessage = dataError?.message;
            document.title = dataError?.meta?.style?.logoTitle || 'JumpStart';

            setTitle(errorMessage);
            setClientName('');

            const newPolicy = {
                termsOfService: dataError?.meta?.pilot.termsOfService,
                privacyPolicy: dataError?.meta?.pilot.privacyPolicy,
            };
            const newStyle = dataError?.meta?.style || {};
            setTenantId(dataError?.meta?.pilot?.tenantId as string);

            if (JSON.stringify(policy) !== JSON.stringify(newPolicy)) {
                setPolicy(newPolicy);
            }
            if (JSON.stringify(style) !== JSON.stringify(newStyle)) {
                setStyle(newStyle);
            }

            if (error?.response?.status === 404) {
                if (!isAuth) {
                    setIsAuth(true);
                }
            } else {
                if (isAuth) {
                    setIsAuth(false);
                }
            }
        }
    }, [pilotData.welcomeObject, pilotData.isError]);

    return useMemo(() => ({
        style,
        clientName,
        title,
        policy,
        tenantId,
        isLoading: pilotData.isLoading,
        pilotData,
    }), [pilotData]);
};

export default usePilotData;
