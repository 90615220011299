import { useQuery } from 'react-query';
import { fetchImage } from '../api/image';

type UseCacheImageType = {
    imageUrl: string | null,
    isLoading: boolean,
}

const useCacheImage = (url: string): UseCacheImageType => {
    const { data: imageUrl, error, isLoading } = useQuery(
        [url],
        () => fetchImage(url),
        {
            refetchOnWindowFocus: false,
            enabled: !!url,
            retry: 0,
        }
    );

    if (error || isLoading) {
        return { imageUrl: null, isLoading };
    }

    return { imageUrl, isLoading } as UseCacheImageType;
}



export default useCacheImage;
