import { useQuery } from 'react-query';
import { useState, useCallback, useEffect } from 'react';
import { getResult } from '../api/result';
import { delayNextRequest } from '../common/constants';
import { Roles, Statuses } from '../common/types/enum';

const supportText = `The engine encountered an exceptional situation that could not be processed automatically. For ongoing issues, please reach out to the <a href="mailto:contact@scaleflow.ai" style="color: #FF6347; text-decoration: underline;" target="_blank" rel="noopener noreferrer">support team</a>.`;

export const useGetResult = (requestId: string, onMessage: (message: any) => void, setQueryProgress: any) => {
    const [isDisable, setIsDisable] = useState<boolean>(false);
    const [startQuery, setStartQuery] = useState(false);

    const fetchData = useCallback(async () => {
        setIsDisable(true);
        return await getResult(requestId);
    }, [requestId]);

    const resetState = () => {
        setQueryProgress({
            status: null,
            progress: 0,
        });
        setIsDisable(false);
    };

    useEffect(() => {
        if (!startQuery && requestId) {
            setTimeout(() => {
                setStartQuery(true);
            }, Number(delayNextRequest));
        }
    }, [requestId]);

    const addDialogMessage = (role: Roles, text: string) => {
        onMessage({ role, text, id: Date.now() });
    };

    const { data, isError, refetch } = useQuery('result', fetchData, {
        enabled: !!requestId && startQuery,
        retry: 3,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data.data?.status === Statuses.DONE) {
                let text = Array.isArray(data.data.responses) ?
                    data.data.responses?.join('\n') :
                    data.data.responses?.context[0]?.content;

                if (text === '') {
                    text = `${supportText}`
                    addDialogMessage(Roles.Error, text);
                    setQueryProgress({
                        status: Statuses.ERROR,
                        progress: 0,
                    });
                    resetState();
                } else {
                    addDialogMessage(Roles.Assistance, text);
                    setQueryProgress({
                        status: null,
                        progress: 0,
                    })
                    resetState();
                }
            }
            // Process
            else if (data.data?.status === Statuses.IN_PROGRESS) {
                setQueryProgress({
                    status: Statuses.IN_PROGRESS,
                    progress: data.data?.progress || 0,
                })
                setTimeout(() => refetch(), Number(delayNextRequest));
            }
            // Error
            else {
                const errorMessage = data?.data?.message || supportText;
                addDialogMessage(Roles.Error, errorMessage);

                setQueryProgress({
                    status: null,
                    progress: 0,
                })
                resetState();
            }
            setStartQuery(false)
        },
        onError: (data: any) => {
            const errorMessage = data?.response?.data?.message || supportText;

            addDialogMessage(Roles.Error, errorMessage);
            setQueryProgress({
                status: null,
                progress: 0,
            })
            resetState();
            setStartQuery(false)
        },
    });

    return { data, isError, refetch, isDisable, setIsDisable };
};

