import React from 'react';
import { SvgIcon, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';

export const CenteredLoader = () => (
    <Box
        sx={{
            display: 'flex',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            transform: 'translateY(-5%)',
            flexDirection: 'column'
        }}
    >
        <RotatingSvgIcon>
            <svg width="200" height="200" viewBox="0 0 201 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 55611867">
                    <circle id="Ellipse 56" cx="100.5" cy="100" r="20.0294" stroke="#4186C6" strokeWidth="7"/>
                    <path
                        id="Star 1"
                        d="M92.8393 49.7993C94.6835 41.635 106.317 41.635 108.161 49.7993C110.421 59.8069 121.905 64.5638 130.58 59.0858C137.657 54.6168 145.883 62.8426 141.414 69.9197C135.936 78.5946 140.693 90.0787 150.701 92.3393C158.865 94.1835 158.865 105.817 150.701 107.661C140.693 109.921 135.936 121.405 141.414 130.08C145.883 137.157 137.657 145.383 130.58 140.914C121.905 135.436 110.421 140.193 108.161 150.201C106.317 158.365 94.6835 158.365 92.8393 150.201C90.5787 140.193 79.0946 135.436 70.4197 140.914C63.3426 145.383 55.1168 137.157 59.5858 130.08C65.0638 121.405 60.3069 109.921 50.2993 107.661C42.135 105.817 42.135 94.1835 50.2993 92.3393C60.3069 90.0787 65.0638 78.5946 59.5858 69.9197C55.1168 62.8426 63.3426 54.6168 70.4197 59.0858C79.0946 64.5638 90.5787 59.8069 92.8393 49.7993Z"
                        stroke="#4186C6"
                        strokeWidth="7"
                    />
                </g>
            </svg>
        </RotatingSvgIcon>
        <StyledTypography>
            Prepping AI...
        </StyledTypography>
        <StyledSubText>
            Please wait a moment while we confirm your settings and permissions.
        </StyledSubText>
    </Box>
);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingSvgIcon = styled(SvgIcon)(({ theme }) => ({
    width: '200px',
    height: '200px',
    animation: `${rotate} 4s linear infinite`,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: 'var(--black, #171D1A)',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '36px',
    marginLeft: theme.spacing(2),
}));

const StyledSubText = styled(Typography)(({ theme }) => ({
    color: 'var(--black, #171D1A)',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    marginTop: theme.spacing(1),
}));
