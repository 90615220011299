import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from '../Sidebar';
import Header from '../Header';

type LayoutProps = {
    children: ReactNode;
    clientName: string;
    title?: string,
    editData: {
        logo?: string | null;
        logoTitle?: string;
        disableClientName?: string;
        sidebarImage?: string | null;
        policies: {
            termsOfService: string,
            privacyPolicy: string
        }
    }
};

const Layout: FC<LayoutProps> = ({ children, clientName, title, editData }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <StyledDiv className="app-container">
      <CssBaseline />
      <Box className="app-container__sidebar">
        <Sidebar
            editData={editData}
            clientName={editData.disableClientName ? null : clientName}
            open={drawerOpen}
            onClose={handleDrawerToggle}
        />
      </Box>
      <Box className="app-container__main">
        <Header
          title={title || ''}
          onDrawerToggleClick={handleDrawerToggle} />
        {children}
      </Box>
    </StyledDiv>
  );
}

export default Layout;

const StyledDiv = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    height: '100vh',
    alignItems: 'stretch',
    overflow: 'hidden',
    width: '100%',

    '.app-container': {
        '&__sidebar': {
            flexShrink: 0,
        },
        '&__main': {
            width: '100%',
            padding: '20px 30px 15px 30px',
            // @ts-ignore
            background: theme.palette.custom?.mainFieldColor,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            alignItems: 'stretch',

            '@media (max-width: 1199px)': {
                padding: '15px 20px',
            },
            '@media (max-width: 899px)': {
                padding: '15px',
            },
        },
    },
}));
