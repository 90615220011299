import queryString from 'query-string';
import { httpClient } from '../HttpClients';
import { authTokenName } from '../common/constants';

export const fetchWelcomeMessage = async (pilotName: string, query?: string) => {
    const headers: Record<string, string | undefined | null> = {
        Authorization: localStorage.getItem(authTokenName),
    };

    if (query && query.includes('t')) {
        const queryParams = queryString.parse(query);
        headers.auth_token = queryParams['t'] as string;
    }

    const { data } = await httpClient.get(`welcome_message/${pilotName}`, {
        headers,
    });
    return data;
};
