import React from 'react';
import Modal from '@mui/material/Modal';
import { Button as MuiButton, Box, Typography } from '@mui/material';
import {styled} from "@mui/material/styles";

// @ts-ignore
export const CustomModal = ({ isOpen, onClose, title, children }) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-title" variant="h3" component="h2" sx={{ textAlign: 'center', mb: 2 }}>
                    {title}
                </Typography>
                <Box sx={{ maxHeight: '70vh', width: '75vw', overflow: 'auto', mt: 2}}>
                    <Typography>
                        <div id="modal-description" dangerouslySetInnerHTML={{ __html: children.props.children }} />
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{ mt: 3}}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </Box>

            </Box>
        </Modal>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,

    '& #modal-description p': {
        paddingTop: '10px',
        paddingBottom: '10px',
    },

    '& #modal-description': {
        padding: '10px',
    },
};

const Button = styled(MuiButton)(({ theme }) => ({
    color: 'white',
}));