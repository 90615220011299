import { convertJsonToStructuredData } from '../keyworkGenerateDiagram';

const defaultColorsRGB = [
    "rgba(40, 157, 208, 0.7)",
    "rgba(116, 209, 139, 0.7)",
    "rgba(87, 210, 214, 0.7)",
    "rgba(60, 187, 136, 0.7)",
    "rgba(2, 52, 94, 0.7)",
    "rgba(0, 240, 255, 0.7)"
];
const borderColor = "rgba(29, 31, 30, 1)";
enum TypeOfChartJsDiagram {
    PolarArea = 'polarArea',
    Pie = 'pie',
    Doughnut = 'doughnut',
    Bar = 'bar',
    Bubble = 'bubble',
    Radar = 'radar',
}

export const updateDiagramColors = (spec: any) => {
    const {
        PolarArea,
        Pie,
        Doughnut,
        Bar,
        Radar,
        Bubble } = TypeOfChartJsDiagram;

    if ([PolarArea, Pie, Doughnut].includes(spec.type)) {
        const datasets = spec.data.datasets.map((set: any) => ({
            ...set,
            backgroundColor: defaultColorsRGB
        }));

        spec.data = {
            ...spec.data,
            datasets,
        }
    } else if ([Bubble, Bar].includes(spec.type)) {
        const datasets = spec.data.datasets.map((set: any, i: number) => ({
            ...set,
            backgroundColor: defaultColorsRGB[defaultColorsRGB.length <= i ? defaultColorsRGB.length - 1 : i],
        }));
        spec.data = {
            ...spec.data,
            datasets,
        }
    } else if ([Radar].includes(spec.type)) {
        const datasets = spec.data.datasets.map((set: any, i: number) => {
            const backgroundColorIndex = defaultColorsRGB.length <= i ? defaultColorsRGB.length - 1 : i;
            return {
                ...set,
                backgroundColor: defaultColorsRGB[backgroundColorIndex],
                borderColor: borderColor,
            }
        });
        spec.data = {
            ...spec.data,
            datasets,
        }
    }
    return spec;
}

export function parseStringDiagramToJson(str: string) {
    const jsonMatch = str.match(/```json\s*([\s\S]*?)\s*```/);
    let jsonString = jsonMatch ? jsonMatch[1] : str;

    const titleMatch = jsonString.match(/<b>(.*?)<\/b>/);
    let titleOfDiagram = titleMatch ? titleMatch[1] : null;


    jsonString = jsonString.trim().replace(/^[\s\S]*?(\{[\s\S]*\})[\s\S]*$/, '$1');

    const stringWithoutComments = jsonString.replace(/\/\/.*$/gm, '');

    const validJsonString = stringWithoutComments
        .replace(/\'/g, '\"')
        .replace(/,\s*(?=[}\]])/g, '')
        .replace(/(\btrue\b|\bfalse\b|\bnull\b)/g, match => match)
        .replace(/(?<=:\s*)"(\btrue\b|\bfalse\b|\bnull\b)"/g, '$1')
        .replace( /(\w+)(?=:)/g, '"$1"');

    try {
        let json = JSON.parse(validJsonString);

        if (titleOfDiagram) {
            json.options = {
                ...json.options,
                plugins: {
                    title: {
                        display: true,
                        text: titleOfDiagram,
                    },
                }
            }
        }

        return json
    } catch (error) {
        console.error('JSON is not valid:', error);
        console.error('string diagram - ', str);
    }
}