import {
    ActionPayload,
    ActionType,
    AddAnswerToQuestionPayload,
    RegisterQuestionnairePayload,
} from '../common/types/actions';
import {QuestionnaireBody} from "../common/types/welcomeMessage";


enum QuestionTypeEnum {
    QUESTION = 'question',
    ADDS = 'adds'
}

type CurrentQuestionType = {
    type?: QuestionTypeEnum;
    index?: number;
    isFinishAllQuestions?: boolean;
    isLastQuestion?: boolean;
}

export type ChatReducerType = {
    answers: {
        questions: AddAnswerToQuestionPayload[];
    };
    questionnaire: {
        questions: QuestionnaireBody[];
    }
    currentQuestion?: CurrentQuestionType
}

export const chatReducer = (state: ChatReducerType, action: ActionPayload) => {
    switch (action.type) {
        case ActionType.FINISH_ANSWERS:
            return {
                ...state,
                currentQuestion: {
                    isFinishAllQuestions: true,
                }
            }
        case ActionType.RESET_CHAT_ANSWERS:
            return {
                ...state,
                answers: {
                    questions: [],
                },
                currentQuestion: {
                    isFinishAllQuestions: true,
                }
            }
        case ActionType.REGISTER_QUESTIONNAIRE:
            const registerPayload = action.payload as RegisterQuestionnairePayload;
            return {
                ...state,
                questionnaire: {
                    questions: registerPayload.questions || [],
                }
            };
        case ActionType.ADD_ANSWER_TO_QUESTION:
            const addAnswerOnQuestionPayload = action.payload as AddAnswerToQuestionPayload;
            return {
                ...state,
                answers: {
                    ...state.answers,
                    questions: [...state.answers.questions, addAnswerOnQuestionPayload],
                },
            };
        case ActionType.NEXT_QUESTION:
            const { questions } = state.questionnaire;
            let nextQuestion: CurrentQuestionType = {
                isFinishAllQuestions: true,
            };

            if (questions.length > state.answers.questions.length) {
                const index = state.answers.questions.length;
                nextQuestion = {
                    index,
                    isFinishAllQuestions: false,
                    isLastQuestion: index === questions.length - 1,
                };
            }
            return {
                ...state,
                currentQuestion: nextQuestion,
            };
        default:
            return state;
    }
}