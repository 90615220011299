export const fetchImage = (url: string) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = (data) => {
            return resolve(url);
        }
        img.onerror = () => reject(null);
        img.src = url;
    });
};
