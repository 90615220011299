import React, { createContext, useContext, useReducer, Dispatch, ReactNode } from 'react';
import { rootReducer } from './reducers';
import { ChatReducerType } from './reducers/chat';
import { ActionPayload } from './common/types/actions';

type State = {
    chat: ChatReducerType
};

type StoreProviderProps = {
    children: ReactNode;
};

const initialState: State = {
    chat: {
        answers: {
            questions: [],
        },
        currentQuestion:  {
            isFinishAllQuestions: false,
            index: 0
        },
        questionnaire: {
            questions: [],
        }
    }
};
const StoreContext = createContext<{ state: State; dispatch: Dispatch<ActionPayload>} | undefined>(undefined);


export const useStore = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(rootReducer, initialState);

    return (
        <StoreContext.Provider value={{ state, dispatch }}>
            {children}
        </StoreContext.Provider>
    );
}
