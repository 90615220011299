import { useQuery } from 'react-query';
import { fetchWelcomeMessage } from '../api/welcomeMessage';
import { WelcomeMessageResponse } from '../common/types/welcomeMessage';
import { sessionNameStorage } from '../common/constants';
import { useGetPilotNameFromUrl } from './useGetPilotNameFromUrl';
import { AxiosError } from 'axios';

const useWelcomeMessage = () => {
    let { pilotName, query } = useGetPilotNameFromUrl();

    if (!pilotName) {
        pilotName = 'default';
    }

    const { data, isLoading, isError, error } = useQuery(
        ['welcomeMessage', pilotName],
        () => fetchWelcomeMessage(pilotName, query),
        {
            retry: 0,
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            enabled: !!pilotName,
        }
    );

    if (data) {
        sessionStorage.setItem(sessionNameStorage, data.session);
    }

    return {
        welcomeObject: data as WelcomeMessageResponse,
        error: error as AxiosError<{ message: string; status: number }>,
        isLoading,
        isError,
    };
};

export default useWelcomeMessage;
