import { chatReducer } from './chat';

type Reducer<TState, TAction> = (state: TState, action: TAction) => TState;
type ReducersMap<TState, TAction> = {
    [key: string]: Reducer<TState, TAction>;
};
const combineReducers = <TState, TAction>(
    reducers: ReducersMap<TState, TAction>
) => (state: TState, action: TAction) => {
    return Object.keys(reducers).reduce((newState: TState, key: keyof ReducersMap<TState, TAction>) => {
        // @ts-ignore
        newState[key] = reducers[key](state[key], action);
        return newState;
    }, {} as TState);
};

export const rootReducer = combineReducers({
    chat: chatReducer,
})