import{ FormEvent, useState } from 'react';
import { TextField as MuiTextField, Button as MuiButton, Box, Container as MuiContainer, Typography } from '@mui/material';
import { useSignIn } from '../../hooks/useSignIn';
import { styled } from '@mui/material/styles';
import { authTokenName } from "../../common/constants";
import variables from "../../styles/vars.module.scss";

type FormFields = {
    email: string;
    password: string;
    tenantId: string;
}
type ErrorsType = {
    email: string;
    password: string;
    message: string;
}
type PropsType = {
    onAuth: (value: boolean) => void;
    tenantId: string;
}

const defaultErrorState = {
    email: '',
    password: '',
    message: '',
}

export const SignInForm = ({ onAuth, tenantId }: PropsType) => {
    const [fields, setFields] = useState<FormFields>({
        email: '',
        password: '',
        tenantId: tenantId
    });
    const [errors, setErrors] = useState<ErrorsType>(defaultErrorState);
    const { isLoading, mutate } = useSignIn();

    const validateEmail = (email: string) => {
        if (!email) {
            return "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            return "Email address is invalid";
        }
        return '';
    };

    const validatePassword = (password: string) => {
        if (!password) {
            return "Pin Code is required";
        }
        return '';
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFields({ ...fields, [name]: value });

        if (name === 'email') {
            setErrors({ ...errors, [name]: validateEmail(value), message: '' });
        }
        if (name === 'password') {
            setErrors({ ...errors, [name]: validatePassword(value), message: '' });
        }
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrors(defaultErrorState);

        const emailError = validateEmail(fields.email);
        const passwordError = validatePassword(fields.password);
        if (emailError) {
            setErrors({ ...errors, email: emailError });
            return;
        } else if (passwordError) {
            setErrors({ ...errors, password: passwordError });
            return;
        }

        mutate(fields, {
            onSuccess: (data) => {
                localStorage.setItem(authTokenName, data.token);
                window.location.reload();
                onAuth(true);
            },
            onError: (error: any) => {
                setErrors({ ...errors, message: error?.response?.data?.message});
                onAuth(false);
            },
        });
    };

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    marginTop: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography className="title-form" component="h1" variant="h5">
                    Sign In
                </Typography>
                <Box className="input-form" component="form" onSubmit={handleSubmit} noValidate>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        type="email"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Pin Code"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                    <Typography className="error-message">{errors.message}</Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={isLoading}
                        sx={{ mt: 1, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

const Container = styled(MuiContainer)(({ theme }) => ({
    width: 480,
    backgroundColor: 'white',
    paddingBottom: '2.0rem',
    borderRadius: 15,
    border: '1px solid #e4e7eb',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& .error-message': {
        color: variables.redColor,
        fontSize: '0.75rem',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        marginTop: '0.5rem',
    },

    '& .title-form': {
        color: '#212121',
        paddingBottom: 15
    },

    '& .input-form': {
        width: '100%',
        '& .MuiInputBase-root': {
            borderRadius: 17
        },
    }
}));

const Button = styled(MuiButton)(({ theme }) => ({
    color: 'white',
    background:  theme.palette.custom?.mainGradientPrimary || variables.mainGradientPrimary,
    borderRadius: 25,
    padding: 10,
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: 16
}));

const TextField = styled(MuiTextField)({
    width: 400,
    borderRadius: 15,
})