import { QuestionnaireBody } from './welcomeMessage';

export enum ActionType {
    REGISTER_QUESTIONNAIRE = 'REGISTER_QUESTIONNAIRE',
    ADD_ANSWER_TO_QUESTION = 'ADD_ANSWER_TO_QUESTION',
    ADD_ANSWER_TO_ADDS = 'ADD_ANSWER_TO_ADDS',
    NEXT_QUESTION = 'NEXT_QUESTION',
    RESET_CHAT_ANSWERS = 'RESET_CHAT_ANSWERS',
    FINISH_ANSWERS = 'FINISH_ANSWERS'
}

export type RegisterQuestionnairePayload = {
    questions: QuestionnaireBody[];
};

export type AddAnswerToQuestionPayload = QuestionnaireBody & {
    answer: string;
};

export type ActionPayload = {
    type: ActionType;
    payload?: RegisterQuestionnairePayload | AddAnswerToQuestionPayload | undefined;
};