import {ChangeEvent, FC, JSX, useCallback, useEffect, useState} from 'react';
import {styled} from "@mui/material/styles";
import {Box, Button, Divider, Stack} from "@mui/material";
import {TextareaAutosize} from '@mui/base/TextareaAutosize';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {useRegister} from '../../../hooks/useRegistratorMessage';
import {useGetResult} from '../../../hooks/useResultMessage';
import {useFileReader} from '../../../hooks/useFileReader';
import {ProgressResult} from '../../../common/types/resultMessage';
import {Statuses} from '../../../common/types/enum';
import {ActionType} from '../../../common/types/actions';
import {useStore} from '../../../store';

type ChatInputProps = {
  scenarioId: string;
  onMessage: (message: any) => void;
  onProgress: (progress: ProgressResult) => void;
};

const ChatInput: FC<ChatInputProps> = ({ onMessage, onProgress, scenarioId }): JSX.Element => {
  const [error, setError] = useState<string>('');
  const { state, dispatch } = useStore();

  const registerMutation = useRegister();
  const { content: fileContent, read, setContent } = useFileReader();

  const [input, setInput] = useState("");
  const [requestId, setRequestId] = useState<string | null>(null);
  const [prevRequestId, setPrevRequestId] = useState<string | null>(null);

  const { isDisable, setIsDisable }
      = useGetResult(requestId as string, onMessage, onProgress);

  useEffect(() => {
    if (fileContent) {
      setInput(fileContent);
      setContent(null);
    }
  }, [fileContent]);

  const uploadTextFromFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      read(file);
    }
    event.target.value = '';
  }, [read]);

  const handleAnswerInput = () => {
    const currentQuestion = state.chat.currentQuestion;
    if (!currentQuestion) return;

    const { index, isLastQuestion } = currentQuestion;
    const lastAnswer = {
      ...state.chat.questionnaire.questions[index || 0],
      answer: input
    }

    dispatch({
      type: ActionType.ADD_ANSWER_TO_QUESTION,
      payload: lastAnswer,
    });

    if (input !== '') {
      onMessage({ role: 'User', text: input, id: Date.now() });
    }
    setInput('');

    if (!isLastQuestion) {
      dispatch({ type: ActionType.NEXT_QUESTION, payload: undefined });
    } else if (state.chat.questionnaire.questions.length > 0) {
      const answersOnQuestions = [...state.chat.answers.questions, lastAnswer];
      handleMessageInput(answersOnQuestions);
      dispatch({ type: ActionType.FINISH_ANSWERS });
    }
  };

  const handleMessageInput = (
      questionAnswers?: any | null,
      addsAnswers: Record<string, string> = {},
  ) => {
    const message = !!questionAnswers ? questionAnswers : input;
    if (state.chat.currentQuestion?.isFinishAllQuestions && message.trim() === "") {
      return;
    }

    if (!questionAnswers && Object.keys(addsAnswers).length === 0) {
      onMessage({ role: 'User', text: input, id: Date.now() });
    }

    setIsDisable(true);
    onProgress({
      progress: 0,
      status: Statuses.IN_PROGRESS,
    });

    const body= {
      scenarioId,
      prevRequestId,
      content: message,
    };

    registerMutation.mutate(body, {
      onSuccess: (data) => {
        setRequestId(data.userRequestId);
        setPrevRequestId(data.userRequestId);
      },
      onError: (err: any) => {
        onMessage({
          role: 'Error',
          text: err?.response?.data?.message as string || 'Unable to create the message. Please try again later',
          id: Date.now(),
        });
      },
    });

    setInput('');
    setRequestId('');
  };

  const handleSend = () => {
    const currentQuestion = state.chat.currentQuestion;
    const question = state.chat.questionnaire.questions[currentQuestion?.index || 0];

    if (question) {
      /** Filters */
      if (question.requires && (!input || input.trim() === '')) {
        setError('The response to the question is mandatory.');
        return;
      } else if (!!question && input.trim().length > 0) {
        if (question.validations?.length?.max && Number(question.validations?.length?.max) < input.trim().length) {
          setError(`Exceeded maximum character count. Maximum allowable is ${question.validations?.length?.max} characters`);
          return;
        } else if (question.validations?.length?.min && Number(question.validations?.length?.min) > input.trim().length) {
          setError(`Minimum allowable is ${question.validations?.length?.min} characters`);
          return;
        }
      }
    }

    setError('');
    if (!currentQuestion?.isFinishAllQuestions) {
      handleAnswerInput();
    } else {
      handleMessageInput();
    }
  };

  return (
    <StyledBox>
      <Stack direction="row" spacing={1.5}>
        <StyledInputGroup direction="row" spacing={1.5} className="fields-group">
          <Button
            disabled={isDisable}
            variant="text"
            component="label"
          >
            <AttachFileIcon fontSize="small" sx={{ color: '#575A63' }} />
            <input
              type="file"
              accept=".txt"
              onChange={uploadTextFromFile}
              hidden
            />
          </Button>
          <Divider orientation="vertical" flexItem />
          <TextareaAutosize
            className="message-input scrollbar-y"
            minRows={1}
            placeholder="Send a message"
            value={input}
            disabled={isDisable}
            onChange={(event)=> setInput(event.target.value)}
          />
          {error && <div className="error-message">{error}</div>}
        </StyledInputGroup>
        <Button
          className="submit-btn"
          color="primary"
          variant="contained"
          disabled={isDisable}
          onClick={handleSend}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.93929 12.6464L7.69205 11.8973L7.69205 11.8973L7.69204 11.8973C5.33883 11.1129 4.16223 10.7207 4.16223 9.99997C4.16223 9.27921 5.33884 8.88701 7.69205 8.10261L16.2052 5.26488C17.861 4.71295 18.6889 4.43699 19.1259 4.87401C19.5629 5.31102 19.287 6.13892 18.735 7.79471L15.8973 16.3079L15.8973 16.3079L15.8973 16.3079C15.1129 18.6611 14.7207 19.8377 14 19.8377C13.2792 19.8377 12.887 18.6611 12.1026 16.3079L11.3535 14.0606L15.7071 9.70708C16.0976 9.31656 16.0976 8.68339 15.7071 8.29287C15.3165 7.90234 14.6834 7.90234 14.2928 8.29287L9.93929 12.6464Z" fill="white"/>
          </svg>
        </Button>
      </Stack>
    </StyledBox>
  );
};

export default ChatInput;

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '40px 0',
  [theme.breakpoints.down('lg')]: {
    padding: '30px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '20px 0',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px 0 0 0',
  },
  '& .fields-group': {
    width: '100%',
  },
  '& .submit-btn': {
    borderRadius: 45,
    display: 'flex',
    height: 54,
    width: 54,
    minWidth: 0,
    padding: 10,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.custom?.colorButtonDefault || 'linear-gradient(0deg, #03A8F9 0%, #03A8F9 100%), linear-gradient(42deg, #03A8F9 0%, #62DF75 100%)',
    boxShadow: '0px 21px 27px -10px rgba(39, 140, 188, 0.33)',
  },
}));

const StyledInputGroup = styled(Stack)(({ theme }) => ({
  position: 'relative',
  marginTop: 40,
  borderRadius: 45,
  border: '1px solid #E2E8F0',
  background: '#FFF',
  // overflow: 'auto',
  alignItems: 'flex-start',

  '& .MuiButton-text': {
    height: 55,
    width: 55,
    minWidth: 0,
    paddingLeft: 15,
  },
  '& .MuiDivider-root': {
    width: 2,
    margin: '12px 0',
    opacity: 0.5,
    background: '#E2E8F0',
  },
  '& .message-input': {
    width: '100%',
    borderRadius: '0px 45px 45px 0px',
    border: 'none',
    margin: 0,
    padding: 15,
    height: 54,
    maxHeight: '40vh',
    overflowY: 'auto !important' ,
    fontSize: 16,
    lineHeight: '150%',
    fontWeight: 500,
    fontFamily: 'Mulish',
    resize: 'vertical',
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      padding: '16px 15px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      padding: '17px 15px',
    },

    '&::placeholder': {
      color: '#718096',
    },
  },
  '& .error-message': {
    color: 'red',
    position: 'absolute',
    bottom: '-20px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '0.8rem',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    textOverflow: 'ellipsis'
  },
}));
