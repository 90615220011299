import {FC, useEffect, useState, useMemo, memo} from "react";
import { styled } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import classNames from "classnames";
import {
  Box,
  Avatar,
  Paper,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import PersonIcon from '@mui/icons-material/Person';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ChatItemLoading from '../ChatItemLoading';
import { Roles } from '../../../common/types/enum';
import { MessageWithDiagram } from './Elements/MessageWithDiagram';
import AssistanceLog from '../../Elements/AssistanceLog';

export type MessageType = {
  id: number;
  text: string;
  role: Sender;
  progress?: number;
}

type Sender = 'Assistance' | 'User' | 'Error'

type MessageProps = {
  message: MessageType,
  logoAssistanceUrl: string
  clientName: string
}

const ChatItem: FC<MessageProps> = (props) => {
  const [isGeneratePdf, setGeneratePdf] = useState<boolean>(false);

  let message = useMemo(() => ({
    ...props.message,
    text: props.message.text,
  }), [props.message]);

  const isAssistance = message.role === Roles.Assistance;
  const isError = message.role === Roles.Error;
  const isUser = message.role === Roles.User;
  const iLoading = message?.progress;

  useEffect(() => {
    return () => {
      message = {
        id: 0,
        text: '',
        progress: 0,
        role: "Assistance"
      };
    }
  })

  let messageClass = classNames({
    'loading-message': iLoading,
    'bot-message': isAssistance,
    'user-message': !isAssistance && !isError,
    'error-message': isError
  });

  const handleDownloadClick = async () => {
    setGeneratePdf(true);
  };

  return (
      <StyledBox className={`message ${messageClass}`}>
        <Avatar className="message__avatar" sx={{
          bgcolor: (isAssistance || isError) ? "primary.main" : "custom.secondaryColor"
        }}>
        {isAssistance && <AssistanceLog url={props.logoAssistanceUrl as string} /> }
        {isError && <ErrorOutlineIcon/>}
        {isUser &&
            <StyledIconBox>
              <PersonIcon sx={{ color: "custom.colorChatIcon" }} />
            </StyledIconBox>
        }
      </Avatar>
      <Paper className="message__body" variant="outlined">
        {
          (iLoading && message.progress) ?
          <ChatItemLoading text={message.text} progress={message.progress} /> :
          <MessageWithDiagram clientName={props.clientName} isGeneratePdf={isGeneratePdf} setGeneratePdf={setGeneratePdf}>{message.text}</MessageWithDiagram>
        }
      </Paper>
      <div className="message__actions">
        {!iLoading && !isGeneratePdf ? (
            <div className="download-icon" onClick={handleDownloadClick}>
              <IconButton size="small"><GetAppIcon /></IconButton>
            </div>
        ) : isGeneratePdf ? (
                <div className="download-icon">
                  <CircularProgress size={30} />
                </div>
        ) : null}
      </div>
    </StyledBox>
  );
}

export default memo(ChatItem);

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: 'center',
  position: 'relative',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1.5),
  },

  '& .message__avatar': {
    marginRight: 13,
    marginTop: 13,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
      marginTop: 6,
    },
  },
  '& .download-icon': {
    cursor: 'pointer',
    transition: 'opacity 0.3s ease-in-out',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '& .message__actions': {
    width: '0px',
    position: 'sticky',
    bottom: '0',
    alignSelf: 'flex-end',
    right: '30px',
  },
  '& .message__body': {
    width: '100%',
    position: 'relative',
    marginRight: '60px',
    padding: theme.spacing(2.5, 3),
    borderRadius: 14,
    border: '1px solid #E3E3E3',
    background: '#ffffff',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 2.5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },

    '& .MuiTypography-root': {
      fontSize: 16,
      fontWeight: 500,
      [theme.breakpoints.down('md')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
  },
  '&.user-message': {
    '& .message__body': {
      border: `1px solid ${theme.palette.custom?.borderChatItemColor}`,
      background: theme.palette.custom?.colorUserChatItem
    }
  },
  '&.loading-message': {
    '& .message__body': {
      border: '1px solid #E3E3E3',
      background: '#ffffff',
    }
  },
  '&.error-message': {
    '& .message__body': {
      fontStyle: 'italic',
      color: '#FF6347',
    }
  }
}));

const StyledIconBox = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  border: `1px solid ${theme.palette.custom?.colorChatIcon}`,
  padding: '7px',
  borderRadius: '50%',
}));
