import {JSX, useEffect, useRef, useState, memo} from 'react';
import {styled} from '@mui/material/styles';
import {Box, Button, ButtonProps} from '@mui/material';
import RefreshIcon from "@mui/icons-material/Refresh";
import ChatItem, {MessageType} from './ChatItem';
import ChatInput from './ChatInput';
import variables from "../../styles/vars.module.scss";
import {Statuses} from '../../common/types/enum';
import {ProgressResult} from '../../common/types/resultMessage';
import {ChatQuestions} from './ChatQuestions';
import {ActionType} from '../../common/types/actions';
import {useStore} from '../../store';

const Chat = (props: any): JSX.Element => {
  const { dispatch} = useStore();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [stateProgress, setStateProgress] = useState<ProgressResult>({
    progress: 0,
    status: null
  });

  const {welcomeObject, isError, assistanceLogoUrl} = props.pilotData;

  useEffect(() => {
    resetState();
    if (welcomeObject?.questions) {
      dispatch({
        type: ActionType.REGISTER_QUESTIONNAIRE,
        payload: {
          questions: welcomeObject.questions || [],
        }
      });
      dispatch({type: ActionType.NEXT_QUESTION});
    }
  }, [welcomeObject, dispatch]);

  const scrollToBottom = () => {
    const container = messagesContainerRef.current;
    if (container) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth',
          });
        });
      }, 70);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, stateProgress]);

  const addMessage = (message: MessageType) => {
      setMessages((prevMessages) => [...prevMessages, message]);
  };

  const resetState = () => {
    setMessages([]);
    setStateProgress({ progress: 0, status: null });
    dispatch({ type: ActionType.RESET_CHAT_ANSWERS });
  }

  const refresh = () => {
    if(typeof window !== 'undefined'){
      window.location.reload();
    }
  };

  if (isError) {
    return (<Box></Box>)
  }

  return (
    <StyledChat>
      <Box className="chat-container scrollbar-y" ref={messagesContainerRef}>
        <ChatQuestions addMessage={addMessage}/>

        {
            welcomeObject &&
            <Box className="welcome-message">
              <ChatItem
                  key={welcomeObject.session}
                  message={{
                    id: Date.now(),
                    role: 'Assistance',
                    text: welcomeObject?.message,
                  }}
                  logoAssistanceUrl={assistanceLogoUrl}
                  clientName={welcomeObject.clientName}
              />
            </Box>
        }

        {messages && messages.map((message) => (
            <ChatItem
                key={message.id}
                message={message}
                logoAssistanceUrl={assistanceLogoUrl}
                clientName={welcomeObject.clientName}
            />
        ))}

        {stateProgress.status === Statuses.IN_PROGRESS && (
            <Box className="button-container">
              <ChatItem key={0} message={{
                id: 0,
                role: 'Assistance',
                progress: stateProgress.progress || 1,
                text: 'Generating responses for you...'
              }}
              clientName={welcomeObject.clientName}
              logoAssistanceUrl={assistanceLogoUrl}
              />
            </Box>
        )}
        {messages.length > 1 && (
            <Box className="button-container">
              <ReloadButton variant="contained" startIcon={<StyledRefreshIcon/>} onClick={refresh}>
                Clear and Restart
              </ReloadButton>
            </Box>
        )}
      </Box>
      <Box className="chat-input">
        <ChatInput
            scenarioId={welcomeObject.scenarioId}
            onProgress={setStateProgress}
            onMessage={addMessage}
        />
      </Box>
    </StyledChat>
  );
};

export default memo(Chat);


const StyledRefreshIcon = styled(RefreshIcon)(({ theme }) => ({
  fill: `${theme.palette.custom?.colorIconClearButton} !important`,
}));

const StyledChat = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  overflow: 'hidden',
  flexDirection: "column",
  maxWidth: 1025,
  width: '100%',
  paddingRight: '30px',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    paddingRight: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingRight: '10px',
  },

  '& .chat-container::-webkit-scrollbar-thumb': {
    background: theme.palette.custom?.chatScrollColor
  },

  '& .welcome-message': {
    whiteSpace: 'break-spaces'
  },

  '& .chat-input': {
    marginLeft: '50px',
  },

  '& .chat-container': {
    flex: 1,
    paddingRight: '30px',
    marginRight: '-30px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '20px',
      marginRight: '-20px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '10px',
      marginRight: '-10px',
    },
  },
  '& .button-container': {
    width: '100%',
    textAlign: 'center',
  },
}));

const ReloadButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: 45,
  border: '1px solid ' + (theme.palette.custom?.colorIconClearButton || '#E2E8F0'),
  boxShadow: 'none',
  backgroundColor: '#fff',
  textTransform: 'none',
  color: variables.textColor,
  fontSize: 16,
  fontStyle: 'normal',
  maxWidth: 280,
  width: '100%',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-0.16px',
  padding: theme.spacing(1.6),
  [theme.breakpoints.down('sm')]: {
    maxWidth: 250,
  },

  '&:hover': {
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  '& .MuiSvgIcon-root': {
    fill: variables.mainColorPrimary,
  },
}));
